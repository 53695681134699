<template>
  <div class="card p-0 mb-12">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <h1 class="card-label">เหตุผลที่ Reject (สามารถเลือกได้มากกว่า 1)</h1>
      </div>
    </div>
    <div class="card-body row">
      <div class="col-lg-2">
        <div
          :key="i"
          v-for="(checkbox, i) in checkboxes"
          class="form-check py-7"
        >
          <input
            class="form-check-input"
            type="checkbox"
            :id="checkbox"
            :disabled="disabled"
            :value="checkbox"
            v-model="model.checkboxes"
          />
          <label
            :style="disabled && 'cursor: default'"
            class="form-check-label ms-3"
            :for="checkbox"
          >
            {{ checkbox }}
          </label>
        </div>
      </div>
      <div class="col-lg-10">
        <textarea
          v-model="model.textarea"
          style="resize: none"
          class="bg-gray-100 col card-img p-8 form-control form-control-transparent"
          :placeholder="!disabled ? 'ใส่ข้อความที่นี้' : ''"
          :disabled="disabled"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'RejectForm',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const checkboxes = ['รูปภาพไม่ถูกต้อง', 'รูปภาพไม่ชัด', 'รูปภาพผิดประเภท'];

    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {
      checkboxes,
      model,
    };
  },
};
</script>

<style scoped lang="sass">
textarea
  min-height: 200px

.material-icons-outlined.text-white
  font-size: 10rem

.overlay-layer
  background-color: #8D8E96
</style>
